import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { Section, Button, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import CTA from "../sections/common/CTA";
import { device } from "../utils";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper darkTheme>
        <Section hero>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Box>
                  <Text variant="tag">Poster Projects</Text>
                  <Title className="my-4">Nobel Prize Laureate Posters</Title>
                </Box>
              </Col>
            </Row>

            <Box pt={["4rem", null, null, "6.25rem"]}>
              <img
                src="/projects/nobel/Front.jpg?nf_resize=fit&w=1140"
                alt=""
                className="img-fluid w-100"
              />
              <img
                src="/projects/nobel/Back.jpg?nf_resize=fit&w=1140"
                alt=""
                className="img-fluid w-100"
              />
            </Box>
          </Container>
        </Section>
        <div className="mt-lg-3">
          <Container>
            <Row className="justify-content-center">
              <Col lg="8">
                <Box>
                  <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                    Subrahmanyan Chandrasekhar is a Nobel Prize Laureate in
                    physics for his theoretical study of evolution of the stars.
                    The Nobel Prize is the first international award given
                    yearly since 1901 for achievements in physics, chemistry,
                    physiology or medicine, literature and peace.
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
          <div className="mt-lg-3 pt-5">
            <Container>
              <Row className="justify-content-center ">
                <Col lg="8" className="mb-4 mb-lg-0">
                  <Text variant="tag">Tools Used</Text>
                  <Title variant="cardBig" className="mt-3">
                    Photoshop, Illustrator
                  </Title>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col xs="12" className="mb-5">
                <img
                  src="/projects/nobel/front-mockup.jpg?nf_resize=fit&w=1140"
                  alt=""
                  className="img-fluid w-100"
                />
              </Col>
              <Col xs="12" className="mb-5">
                <img
                  src="/projects/nobel/back-mockup.jpg?nf_resize=fit&w=1140"
                  alt=""
                  className="img-fluid w-100"
                />
              </Col>
            </Row>
          </Container>
        </Section>
        <Section bg="dark" className="pt-0">
          <Container>
            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="/jm-album-cover">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                `}
                >
                  Album Covers - Joyce Manor
                </Button>
              </Link>
            </div>
          </Container>
        </Section>
        <Box py={4}>
          <CTA />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
